import GATSBY_COMPILED_MDX from "/opt/buildhome/repo/content/sections/knowledge/card-payments/refunds-and-reversals.mdx";
import React from "react";
import {MDXContent} from "./mdx-content";
export const ArticleMDXRenderer = _ref => {
  var _data$article$intro;
  let {data, children} = _ref;
  return React.createElement(MDXContent, {
    title: data.article.title,
    metaTitle: data.article.metaTitle,
    description: (_data$article$intro = data.article.intro) !== null && _data$article$intro !== void 0 ? _data$article$intro : data.article.parent.excerpt,
    metaDescription: data.article.metaDescription,
    path: data.article.path
  }, children);
};
const query = "3995270786";
ArticleMDXRenderer
export default function GatsbyMDXWrapper(props) {
  return React.createElement(ArticleMDXRenderer, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
